<template>
  <div class="qr-code-container" >
    <div class="qr-content">
      <div class="qrcode-container">
        <div style="width: 100%" ref="qrcode"></div>
      </div>
      <div class="submit-price" style="text-align: center;margin-top: 20px;display: block">￥<span>{{price}}</span></div>
      <div class="pai-hint">请使用「微信扫一扫」支付</div>
      <div  class="pay-close iconfont icon-guanbi2" @click="closePay"></div>
    </div>

  </div>
</template>

<script>

import QRCode from 'qrcodejs2'

export default {
  name: 'HelloWorld',
  props: {
    msg: String,
    price:String,
    id:String,
    type:String
  },
  data(){
    return{
      inter:{}
    }
  },
  mounted() {
    console.log(this.msg,this.price,this.id,this.type)
      this.generateQRCode()

  },
  methods:{
    generateQRCode(txt) {
      if (this.qrcode != null) {
        this.qrcode.clear() // 清除原来的二维码
        this.$refs.qrcode.innerHTML=''
      }


      this.qrcode = new QRCode(this.$refs.qrcode, {
        width: 200,
        height: 200,
        text: this.msg
      })
      this.selectIsPay();
    },
    selectIsPay(){
        this.inter=setInterval(async ()=>{
          if (this.type == 1){
            console.log("我查询攻击结构数据----")
            let item = await this.$http.get(`/admin/fileDownHistory/getPdfUrlByPayId?${this.$common.parseParam({payId:this.id})}`)
            console.log("返回参数",item)
            if (item != null){
              clearInterval(this.inter)
              this.$parent.paySuccess(item)
            }
          }else if (this.type == 2){
            console.log("我查询了套餐支付-----")
            let item = await this.$http.get(`/admin/userCombo/getOrderStatusByPayId?${this.$common.parseParam({payId:this.id})}`)
            console.log("返回参数",item)
            if (item == 1){
              clearInterval(this.inter)
              this.$parent.paySuccess(item)
            }
          }

        },2000)


    },
    closePay(){
      this.$parent.closePay()
      clearInterval(this.inter)
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>



.qr-code-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.qr-content{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background: #fff;
  padding: 30px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  border-radius: 6px;
}


.pai-hint{
  font-size: 12px;
  margin-top: 20px;
  text-align: center;
}

.pay-close{
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translate(-50%,0);
  color: #555;
  font-size: 20px;
  cursor: pointer;
}

.vip-t{
  margin-left: 8px;
  padding: 0.5px 8px;
  border-radius: 4px;
  font-size: 11px;
  line-height: 20px;
  border: 0.5px solid hsla(0,0%,100%,.3);
  background: hsla(0,0%,100%,.5);
  color: #505a71;
  font-weight: 400;
  /* margin-top: 1px; */
  position: relative;
  top: -1px;
}


.submit-price {
  font-size: 14px;
  position: relative;
  color: #fa2323;
  display: inline-block;
  top: -2px;
}

.submit-price span {
  font-family: DIN Alternate;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  position: relative;
  /* top: -5px; */
  margin-right: 15px;
}

</style>
