<template>
  <div className="login-container" v-show="show">
    <div className="login-content-wrap" @click.stop="close">
      <div className="login-content">
        <div className="login-title">大礼哥二维码登录</div>
        <img class="qh-img" src="../../assets/qh.png">
        <div class="qrcode-container">
          <div style="width: 100%" ref="qrcode"></div>
        </div>
        <div class="login-txt">使用大礼哥小程序扫一扫登录</div>
        <div class="login-txt">'大礼哥'</div>
        <div class="login-bottom">
          <div class="f-wrap">
            <span>密码登录</span>
            <span class="f-link">|</span>
            <span>新账号注册</span>
          </div>
          <div class="login-txt1">
            未注册手机验证后自动登录，登录或注册即代表同意<span>用户协议</span>和<span>隐私政策</span>
          </div>

          <div class="login-txt1">
            未满18周岁未成年人请勿自行注册，其注册、登录账号及使用服务需征得监护人同意
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>

import QRCode from 'qrcodejs2'

export default {
  name: 'HelloWorld',
  props: {
    show: Boolean
  },
  data() {
    return {
      judge: true
    }
  },
  async mounted() {



  },
  watch: {
    async show(newValue, oldValue) {
      console.log("数据发生变化", newValue == true)
      let key = "daligeData-" + this.guid()
      this.qrcodeText = key
      this.generateQRCode(key)
      if (newValue == true) {
        console.log("wochufale ")
        this.judge = true
        let res = await this.$http.get(`/admin/groupLogin/setKey?${this.$common.parseParam({key: this.qrcodeText})}`)
        console.log("返回数据", res)
        this.inquiry()
      } else {
        console.log("----")
        this.judge = false
      }

    }
  },
  methods: {
    close() {
      //关闭弹框------子组件修改props
      console.log("----")
      this.$parent.closeShow()

    },

    generateQRCode(txt) {
      if (this.qrcode != null) {
        this.qrcode.clear() // 清除原来的二维码
      }

      this.$refs.qrcode.innerHTML=''

      this.qrcode = new QRCode(this.$refs.qrcode, {
        width: 200,
        height: 200,
        text: txt
      })
    },
    guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
    },
    async inquiry() {
      console.log("================",this.qrcodeText)
      let res = await this.$http.get(`/admin/groupLogin/getKey?${this.$common.parseParam({key: this.qrcodeText})}`)
      if ((res == '' || res == null) && this.judge == true) {

        setTimeout(() => {
          this.inquiry()
        }, 2000)

      } else if (res != '' && this.judge == true && res != null) {
        this.$message.success('登陆成功~')
        this.judge = false

        console.log(res)
        localStorage.setItem('token', res)
        this.$parent.loginSuccess()


        let parentId=localStorage.getItem("parentId")
        if (parentId !=null && parentId !=''){
          this.$http.get(`/admin/user/addParentId?${this.$common.parseParam({parentId:parentId})}`)
        }
        //

        // localStorage.setItem('type',"2")
        // let company = await this.$http.get(`/admin/company/getCompanyDetailAdmin?${this.$common.parseParam({})}`)
        // localStorage.setItem('company',JSON.stringify(company))
        // this.$router.push('/companyUser')

      }

    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  z-index: 102;
}

.login-content-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  padding: 100px;
  box-sizing: border-box;
}

.qh-img {
  position: absolute;
  width: 67px;
  right: 12px;
  top: 12px;
}

.login-content {
  background: #fff;
  border-radius: 6px;
  height: 100%;
  min-height: 570px;
  max-height: 600px;
  width: 400px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 30px 35px;
  position: relative;
}

.login-bottom {
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 0px 40px;
  padding-bottom: 35px;
}

.login-txt1 {
  color: #999999;
  font-size: 10px;
  margin-top: 5px;
  line-height: 16px;
}

.login-txt1 span {
  color: #ff7300;
  padding: 0 4px;
}

.f-link {
  display: inline-block;
  margin: 0 10px;
}

.f-wrap {
  color: #888;
  text-align: center;
  font-size: 14px;
  margin-bottom: 50px;
  cursor: pointer;
}

.login-title {
  font-size: 16px;
  color: #282828;
  font-weight: 550;
  padding-bottom: 20px;
  border-bottom: 1px solid #eeeeee;
}

.qrcode-container {

  width: 200px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 27px;
}

.login-txt {
  font-size: 13px;
  color: rgb(55, 55, 55);
  line-height: 20px;
  text-align: center;
  font-weight: 440;
  margin-bottom: 4px;
}


</style>
