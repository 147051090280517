<template>
  <div class="vip-components-container">
    <div class="vip-content">
      <div class="vip-tab" :class="type == 1 ?'b-1-bk':type == 2 ?'b-2-bk':'b-3-bk'">
        <div class="user-info">
          <div class="left">
            <img :src="user.avatarurl">
            <div class="info">
              <div class="user-name" :class="type == 3?'white':''">{{ user.nickname }}<span class="vip-t">{{user.vipType == 1?'标准版会员':'高级版会员'}}</span></div>
              <div class="desc" :class="type == 3?'white':''" v-if="user.vipType == null ">你还未开通过会员</div>
              <div class="desc" :class="type == 3?'white':''" v-else >当前会员有效期：{{user.vipExpireTime}}</div>
            </div>
          </div>

          <div class="right">
            <p :class="type == 3?'white':''">常见问题</p><span :class="type == 3?'white':''">|</span>
            <p :class="type == 3?'white':''" @click="conversionVip">激活码兑换</p>
          </div>

        </div>
        <div class="tab-box">
          <div class="tag-item " @click="cutType(1)" :class="type == 1?'b-1':''">
            <p class="title  " :class="type == 1?'b-1-choose-color':type == 3?'white':''">标准版</p>
            <p class="range" :class="type == 3?'white':''">（个人中/小微企业商用）</p>
          </div>
          <div class="tag-item " @click="cutType(2)" :class="type == 2?'b-2':''">
            <p class="title  " :class="type == 2?'b-2-choose-color':type == 3?'white':''">高级版</p>
            <p class="range" :class="type == 3?'white':''">（中小微企业商用）</p>
          </div>
          <div class="tag-item " @click="cutType(3)" :class="type == 3?'b-3':''">
            <p class="title  " :class="type == 3?'b-3-choose-color':''">企业版</p>
            <p class="range">（企业多人协同工作）</p>
          </div>
        </div>
      </div>

      <div class="combo-container">
        <span class="close iconfont icon-guanbi2" @click="close"></span>
        <div class="vip-left">
          <div class="left-title">
            <div class="version-name">基础版会员</div>
            <div class="version-desc">适用于使用频率不高的个人，中小微企业使用</div>
          </div>
          <div class="vip-equity">全站免费素材不限次数下载，免费获取</div>
          <div class="vip-equity">每日5次VIP素材下载次数</div>
          <div class="vip-equity">享工艺文件极速下载权限</div>
          <div class="vip-equity">专属接单渠道扩展</div>
          <div class="vip-equity">盒型报价展现权限开通</div>
          <div class="vip-equity">专属客服24小时服务</div>

        </div>
        <div class="vip-right">
          <div class="vip-title">请选择购买套餐包</div>
          <div class="vip-wrap " @click="cutCombo(item)"
               :class="item.vipComboId == chooseCombo.vipComboId ?'choose-combo':''" v-for="(item,i) in list">
            <div class="vip-tag" v-if="item.tag != '' ">{{ item.tag }}</div>
            <div class="vip-name">{{ item.comboName }}</div>
            <div class="price-container">
              <div class="discount-price">￥<span>{{ item.price }}</span></div>
              <div class="init-price">原价￥{{ item.oldPrice }}元</div>
            </div>
            <div class="vip-bottom" :class="item.vipComboId == chooseCombo.vipComboId ?'choose-combo-bottom':''">
              {{ item.childrenTitle }}
            </div>
          </div>


        </div>
      </div>
      <div class="submit-container">
        <div class="old-price">原价 {{ chooseCombo == null ?'0.00':chooseCombo.strOldPrice }}元</div>
        <div class="submit-price">￥<span>{{  chooseCombo == null ?'0.00':chooseCombo.strPrice }}</span></div>
        <div class="submit-btn" @click="pay(chooseCombo)">立即支付</div>
      </div>
    </div>

<!--    <div class="qr-code-container" v-show="payCode">-->
<!--      <div class="qr-content">-->
<!--        <div class="qrcode-container">-->
<!--          <div style="width: 100%" ref="qrcode"></div>-->
<!--        </div>-->
<!--        <div class="submit-price" style="text-align: center;margin-top: 20px;display: block">￥<span>{{ chooseCombo.strPrice }}</span></div>-->
<!--        <div class="pai-hint">请使用「微信扫一扫」支付</div>-->
<!--        <div  class="pay-close iconfont icon-guanbi2" @click="closePay"></div>-->
<!--      </div>-->

<!--    </div>-->

    <Pay :msg="txt" :price="price"  type="2" :id="orderId" v-if="showPay"></Pay>

  </div>
</template>

<script>

import QRCode from 'qrcodejs2'
import Pay from "@/components/user/Pay";

export default {
  name: 'HelloWorld',
  props: {
    user: Object
  },
  components:{
    Pay
  },
  data() {
    return {
      type: 1,
      chooseCombo: {},
      list: [],
      txg:'',
      orderId:'',
      price:'',
      txt:'',
      id:'',
      payCode:false,
      showPay:false
    }
  },
  mounted() {


  },
  created() {
    this.getComboList(1)
  },
  methods: {
    generateQRCode(txt) {
      if (this.qrcode != null) {
        this.qrcode.clear() // 清除原来的二维码
        this.$refs.qrcode.innerHTML=''
      }



      this.qrcode = new QRCode(this.$refs.qrcode, {
        width: 200,
        height: 200,
        text: txt
      })
    },
    cutType(type) {
      this.type = type
      this.getComboList(type)
    },
    close() {
      this.$parent.closeVipShow()
    },
    conversionVip(){
      this.$prompt('请输入会员兑换码', '提示', {
        confirmButtonText: '确定兑换',
        cancelButtonText: '取消',
        beforeClose: async (action, instance, done) => {
          console.log(instance)
          console.log()
          let code=instance.inputValue
          console.log(code)
          if (code == ''|| code == null){
            this.$message.warning("请填写激活码")
            return
          }
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = '验证中...';
            let res = await this.$http.get(`/admin/shareVip/verificationVipCode?${this.$common.parseParam({code:code})}`)
            console.log(res)
            if (res !=null && res.state == 2001){
              this.$message.warning(res.message)
              instance.confirmButtonText = '确认兑换';
            }else{
              done();
              this.$message.success("激活码已成功兑换")
              this.$parent.getUserDetail()
            }
            instance.confirmButtonLoading = false

          } else {
            done();
          }
        }
      }).then(({ value }) => {

      }).catch(() => {

      });
    },
    paySuccess(){
        this.showPay=false
      this.$parent.getUserDetail()
      this.$message.success("VIP已开通成功 ~ ")
    },
    async getComboList(type) {
      let res = await this.$http.get(`/admin/vipCombo/noLogin/getVipComboList?${this.$common.parseParam({type: type})}`)

      res.forEach(item => {
        item.strOldPrice = item.oldPrice.toFixed(2)
        item.strPrice = item.price.toFixed(2)
      })
      console.log(res)
      if (res.length > 0) {
        this.chooseCombo = res[0]
      }else{
        this.chooseCombo=null
      }
      this.list = res
    },
    cutCombo(data) {
      this.chooseCombo = data
    },
    closePay(){
      this.payCode=false
    },
    async pay(row) {

      if (row == null){
        this.$message.error("请选择对应的套餐包再支付")
        return
      }

      let user=this.user
      if (user.vipType != null && new Date(this.user.vipExpireTime).getTime() > new Date().getTime() && user.vipType != row.vipType ){
        this.$message.error("你已开通其他类型的会员，请过期后再开通该会员")
        return
      }

      //发送请求
      let res = await this.$http.post('/admin/userCombo/addUserComboAdmin', {
        vipComboId:row.vipComboId,
        comboType:row.vipType,
        payPrice:row.price,
        comboName:row.comboName,
        day:row.day
      })

      this.txt=res.qrcode
      this.orderId=res.payId
      this.price=row.price.toFixed(2)
      this.showPay=true

      // this.generateQRCode("我是隔壁的泰山压，抓住爱情的特曼")

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.vip-components-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .6);
  z-index: 100;
}


.close {
  position: absolute;
  top: -30px;
  right: -40px;
  color: #fff;
  font-size: 29px;
  cursor: pointer;
}


.vip-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 900px;
  border-radius: 16px;
  min-height: 600px;
  background: #fff;

}

.vip-tab {
  position: relative;
  width: 100%;
  height: 120px;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  border-radius: 16px 16px 0px 0px;
}

.user-info {
  padding: 8px 24px 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.tab-box {
  display: flex;
  border-top: 1px solid hsla(0, 0%, 100%, .4);
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
}

.tag-item {
  background: hsla(0, 0%, 100%, .2);
  height: 48px;
  position: relative;
  cursor: pointer;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  box-sizing: border-box;
  color: #1b2337;
}


.b-1::after {
  position: absolute;
  content: "";
  left: 50%;
  margin-left: -152px;
  bottom: 0;
  height: 56px;
  width: 311px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../assets/vip/b-1.svg");
}

.b-1-choose-color {
  color: #0773fc;
}

.b-1-bk {
  background-image: url("../../assets/vip/t-1.svg");
}


.b-2::after {
  position: absolute;
  content: "";
  left: 50%;
  margin-left: -152px;
  bottom: 0;
  height: 54px;
  width: 311px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../assets/vip/b-2.svg");
}

.b-2-choose-color {
  color: #f49e5c;
}

.b-2-bk {
  background-image: url("../../assets/vip/t-2.svg");
}


.b-3::after {
  position: absolute;
  content: "";
  left: 50%;
  margin-left: -152px;
  bottom: 0;
  height: 56px;
  width: 311px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../assets/vip/b-3.svg");
}

.b-3-choose-color {

}

.b-3-bk {
  background-image: url("../../assets/vip/t-3.svg");
}

.title {
  z-index: 2;
  opacity: 1;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.range {
  z-index: 2;
  font-size: 12px;
  line-height: 20px;
  color: #222;
}


.white {
  color: #fff !important;
}

.info {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 12px;
  box-sizing: border-box;
}

.left img {
  height: 38px;
  width: 38px;
  border: 1px solid hsla(0, 0%, 100%, .3);
  border-radius: 50%;

}

.user-name {
  /*overflow: hidden;*/
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 6em;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: #1b2337;
}

.desc {
  font-family: PingFang SC;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #505a71;
}

.left {
  display: flex;
}


.right {
  cursor: pointer;
  color: #505a71;
  font-size: 14px;
  line-height: 22px;
  float: right;
}

.right p {
  display: inline-block;
}

.right span {
  display: inline-block;
  margin: 0 10px;
}


.combo-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  padding: 16px 17px 22px 20px;
  overflow: hidden;
  background: #fff;
  box-sizing: border-box;
  border-radius: 0px 0px 16px 16px;
}

.vip-left {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: flex-start;
  flex-direction: column;
  width: 200px;
  min-height: 446px;
  background: #f5f7fd;
  padding: 12px 16px 16px 16px;
  border-radius: 12px;
  overflow: hidden;
  box-sizing: border-box;
}


.vip-right {
  width: 632px;
  margin-left: 32px;
  box-sizing: border-box;
}

.left-title {

  padding-bottom: 15px;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 15px;
}

.version-name {
  padding-left: 30px;
  position: relative;
  font-weight: 500;
  color: #1b2337;
  font-size: 16px;
  line-height: 24px;
}

.version-desc {
  margin-top: 6px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #505a71;

}


.vip-equity {
  font-size: 12px;
  color: #505a71;
  margin-bottom: 14px;
  line-height: 20px;
}

.vip-title {
  color: #505a71;
  font-family: PingFang SC;
  font-weight: 700;
  font-size: 15px;
  line-height: 40px;
  letter-spacing: 0;
  text-align: left;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.vip-name {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  text-align: center;
  padding: 16px 0 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.vip-wrap {
  position: relative;
  width: 146px;
  height: 150px;
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 143px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: 12px;
  color: #1b2337;
  cursor: pointer;
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 15px;
}

.vip-tag {
  position: absolute;
  top: -12px;
  right: -2px;
  padding: 0 8px;
  height: 24px;
  background: linear-gradient(90deg, #ff6464 2.95%, #fa2323 99.68%);
  border-radius: 12px 8px 8px 4px;
  font-size: 12px;
  color: #fff;
  text-align: center;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}


.vip-wrap:nth-child(4n+1) {
  margin-right: 0px;
  /* 样式 */
}

.vip-bottom {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 0 0 9px 9px;
  width: 100%;
  height: 24px;
  font-size: 12px;
  line-height: 27px;
  font-weight: 400;
  text-align: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: rgba(255, 201, 160, .2980392156862745);
  color: #6b3d1e;
}

.choose-combo {
  background: #fffaf6;
  border: 2px solid #febf93;
}

.choose-combo-bottom {
  background: #ffc9a0;
}

.price-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.discount-price {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row;
  font-size: 14px;
  line-height: 25px;
}


.discount-price span {
  font-size: 24px;
  margin: 0 2px;
  line-height: 32px;
  color: #6b3d1e;
  font-weight: 700;
}


.init-price {
  font-size: 12px;
  color: rgba(27, 35, 55, .4);
  line-height: 20px;
  font-weight: 400;
  margin-top: 4px;
  text-decoration: line-through;
}


.old-price {
  background: #fee9e9;
  /* float: left; */
  border-radius: 4px 4px;
  padding: 0 6px;
  color: #fa2323;
  /* margin-top: 0px; */
  margin-right: 10px;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  top: -5px;
  position: relative;
  display: inline-block;
}

.submit-price {
  font-size: 14px;
  position: relative;
  color: #fa2323;
  display: inline-block;
  top: -2px;
}

.submit-price span {
  font-family: DIN Alternate;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  position: relative;
  /* top: -5px; */
  margin-right: 15px;
}

.submit-btn {
  /* position: absolute; */
  /* top: -12px; */
  padding: 0 20px;
  height: 37px;
  background: linear-gradient(90deg, #ff6464 2.95%, #fa2323 99.68%);
  border-radius: 8px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 37px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  display: inline-block;
  font-weight: 700;
  cursor: pointer;
}


.submit-container {
  position: absolute;
  bottom: 20px;
  right: 20px;
  /*width: 300px;*/
  display: block;
  text-align: right;
}

.qr-code-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.qr-content{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background: #fff;
  padding: 30px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  border-radius: 6px;
}


.pai-hint{
  font-size: 12px;
  margin-top: 20px;
  text-align: center;
}

.pay-close{
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translate(-50%,0);
  color: #555;
  font-size: 20px;
  cursor: pointer;
}

.vip-t{
  margin-left: 8px;
  padding: 0.5px 8px;
  border-radius: 4px;
  font-size: 11px;
  line-height: 20px;
  border: 0.5px solid hsla(0,0%,100%,.3);
  background: hsla(0,0%,100%,.5);
  color: #505a71;
  font-weight: 400;
  /* margin-top: 1px; */
  position: relative;
  top: -1px;
}
</style>
