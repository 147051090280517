import { createApp } from 'vue'
import Vue from 'vue'
import App from './App.vue'
import './css/iconfont.css'
import './css/app.css'
import ElementPlus from 'element-plus' //全局引入

import router from './router/index.js';
import axios from './plugins/http';
import common from './utils/common.js'

// import 'element-plus/lib/theme-chalk/index.css'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

const myMixin = {
    created() {
        this.$BaseImage = 'http://image.7871092.vip/';
        this.$http = axios// 将axios到原型
        this.$common = common// 将axios到原型
    }
}





const app = createApp(App)
app.use(router)
app.use(ElementPlus,{
    locale:zhCn
})

app.mixin(myMixin);

app.mount('#app')
