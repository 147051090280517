<template>
  <div class="header-container">
    <div class="header-left">
      <div class="logo-wrap" @click="nav('/')"><img src="../../assets/logo.png" >
        <div class="logo-txt">
          大礼哥
        </div>
        </div>

      <div class="nav-wrap">
        <div class="nav " :class="path == '' || path == ''?'choose-nav':'' || path =='/' ?'choose-nav':''" @click="nav('/')" >首页</div>
        <div class="nav" :class="path == '/craftList' ?'choose-nav':'' " @click="nav('/craftList')">工艺结构库</div>
        <div class="nav"   @click="noHint" >在线设计师</div>

<!--        <div class="nav">更多分类  <span class="iconfont icon-xiajiantou"></span></div>-->
        <div class="nav" :class="path == '/dieList' ?'choose-nav':'' " @click="nav('/dieList')" >全国成品刀模</div>
      </div>

    </div>

    <div class="header-right">

      <div class="right-nav-">
        <div class="add" @click="navLogin('/addProduction')"><img src="../../assets/add.png">创作中心</div>
        <div @click="openVip" v-if="!isVip">个人VIP</div>
        <div @click="openVip" class="vip vip1" v-if="isVip && user.vipType == 1" >标准版VIP</div>
        <div @click="openVip" class="vip vip2" v-if="isVip && user.vipType == 2" >高级版VIP</div>
<!--        <div @click="noHint" >企业/团队版</div>-->
      </div>


        <div class="user-head" v-if="token !='' && token !=null ">
          <img :src="user.avatarurl">
          <span class="iconfont icon-xiajiantou"></span>
          <div class="user-item-wrap">
            <div class="user-item-container">
              <div class="user-title-wrap">
                <div class="user-title" >
                  <img :src="user.avatarurl">
                  <div class="name-wrap">
                    <div class="user-name-title">{{user.nickname}}</div>
                    <div class="date">ID:U000001</div>
                  </div>
                </div>
              </div>
              <div class="user-item-link">

                <div class="user-item">
                  <span class="iconfont icon-fenxiangzhuanqian"></span>
                  <div class="user-name" @click="navLogin('/shareUserList')" >分享赚钱</div>
                </div>

                <div class="user-item">
                  <span class="iconfont icon-vip1"></span>
                  <div class="user-name" @click="navLogin('/vipBuyList')"  >VIP购买记录</div>
                </div>
                <div class="user-item">
                  <span class="iconfont icon-bianji1"></span>
                  <div class="user-name"  @click="navLogin('/productionManger')" >创作者中心</div>
                </div>
              </div>


              <div class="user-item-link">
                <div class="user-item">
                  <span class="iconfont icon-shoucang"></span>
                  <div class="user-name" @click="navLogin('/collectList')" >我的收藏/订阅</div>
                </div>
                <div class="user-item">
                  <span class="iconfont icon-jilu"></span>
                  <div class="user-name" @click="navLogin('/userDownHistory')">下载/获取记录</div>
                </div>
              </div>


              <div class="user-item-link">
                <div class="user-item">
                  <span class="iconfont icon-31wode"></span>
                  <div class="user-name" @click="noHint" >个人主页</div>
                </div>
                <div class="user-item">
                  <span class="iconfont icon-31shezhi"></span>
                  <div class="user-name" @click="noHint" >账号设置</div>
                </div>
                <div class="user-item">
                  <span class="iconfont icon-tuichudenglu1"></span>
                  <div class="user-name" @click="outLogin" >退出登录</div>
                </div>
              </div>


            </div>
          </div>

        </div>

        <el-button class="login-btn" v-else type="primary" @click="showLogin">登录/注册</el-button>
    </div>

    <div class="input-container">
      <input placeholder="搜索发现更多盒型">
      <span class="iconfont icon-sousuo" @click="noHint" ></span>
    </div>



  </div>

  <Login :show="loginShow"></Login>
  <VipCompent :user="user" v-if="showVip"></VipCompent>
</template>

<script>

import Login from "@/components/user/Login";
import VipCompent from "@/components/vip/VipCompent";

export default {

  name: 'HelloWorld',
  props: {
  },
  data(){
    return {
      loginShow:false,
      token:'',
      user:{},
      path:'',
      isVip:false,
      showVip:false
    }

  },
  components:{
    Login,
    VipCompent
  },
  created() {
    let token=localStorage.getItem("token")
    this.token=token
    console.log(token,"我是token")
    if (token != 'null' && token != '' && token != undefined){
      console.log("我查")
      this.getUserDetail()
    }

    let href=window.location.href
    if (href.indexOf("key=") != -1){
      console.log(href.substring(href.indexOf("key=")+4))
      localStorage.setItem("parentId",href.substring(href.indexOf("key=")+4))
    }

  },
  methods:{
    openVip(){
        this.showVip=true
    },
    closeVipShow(){
      this.showVip=false
    },
    closeShow(){
      this.loginShow=false
    },
    showLogin(){
      this.loginShow=true
    },
    outLogin(){
      this.token='';
      localStorage.removeItem("token")
      this.$message.success({message:'你已成功退出登录'})
    },
    noHint(){
      this.$message({
        message: '该功能正在开发中~',
        type: 'warning'
      });
    },
    loginSuccess(){
      this.getUserDetail();
      let token=localStorage.getItem("token")
      this.token=token
      this.closeShow();
    },
    /**
     * 获取表格数据
     */
    async getUserDetail () {

      this.loading = true
      let res = await this.$http.get(`/admin/user/getUserDetail`)
      res.avatarurl=this.$BaseImage+res.avatarurl

      localStorage.setItem("id",res.userId)
      localStorage.setItem("openid",res.openid)
      // localStorage.setItem("username",res)
      this.user=res

      if (res.vipType != null && new Date(res.vipExpireTime).getTime() > new Date().getTime()){
          this.isVip=true
      }else{
        this.isVip=false
      }
    },
    nav(url){
      this.path=url
      this.$router.push(url)
    },
    navLogin(url){
      if (this.token  != null && this.token != '' ){
        this.path=url
        this.$router.push(url)
      }else{
        this.showLogin()
      }

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .header-container{
    min-width: 1200px;
    box-sizing: border-box;
    /*padding: 0px 50px;*/
    background: #fff;
    border-bottom: 1px solid #f5f5f5;
    height: 70px;
    padding-left: 660px;
    padding-right: 500px;
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 100;
  }


  .logo-wrap{
    height: 70px;
    line-height: 70px;
    position: relative;
    padding-left: 55px;
    display: inline-block;
    cursor: pointer;
  }

  .logo-wrap img{
    width: 50px;
    position: absolute;
    top:50%;
    left: 0px;
    transform: translate(0,-50%);
  }

  .logo-txt{
    font-size: 21px;
    font-weight: 700;
    color: #333;
    display: inline-block;
  }

  .nav{
    color: rgba(0, 0, 0, 0.65);
    font-size: 15px;
    display: inline-block;
    line-height: 70px;
    height: 70px;
    padding-right: 45px;
    cursor: pointer;
  }

  .nav span{
    font-size: 13px;
  }

  .choose-nav{
    color: #69ad80;
    font-weight: 500;
  }

  .nav-wrap{
    display: inline-block;
    margin-left: 75px;
    vertical-align: top;
  }

  .header-left{
    position: absolute;
    top: 0px;
    left: 35px;
  }

  .header-right{
    position: absolute;
    right: 70px;
    top: 0px;
    height: 70px;
    display: inline-block;
  }

  .user-head{
    position: absolute;
    right: 0px;
    top: 48%;
    transform: translate(0,-50%);
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
  }


  .login-btn{
    position: absolute;
    right: -30px;
    top: 48%;
    transform: translate(0,-50%);
    white-space: nowrap;
    vertical-align: middle;
  }


  .user-item-wrap{
    position: absolute;
    padding-top: 35px;
    top: 35px;
    right: 0px;
    width: 264px;
    display: none;
  }

  .user-item-container{
    width: 100%;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
    /*padding: 30px;*/
    box-sizing: border-box;
    overflow: hidden;

  }

  .user-item-wrap:hover {
    display: block;
  }


  .user-head:hover .user-item-wrap {
      display: block;
  }

  .user-title-wrap{

  }

  .user-title{
    position: relative;
    min-height: 50px;
    padding-left: 55px;
    margin: 0 25px;
    margin-top: 25px;
    padding-bottom: 10px;
  }

  .user-title img{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 43px;
    border-radius: 300px;
    height: 43px;
  }

  .user-name-title{
    font-size: 16px;
    color: #333;
    font-weight: 450;
    /* margin-top: 12px; */
    padding-top: 2px;
  }


  .user-name{
    font-size: 14px;
    color: gray;
    display: inline-block;
    /* line-height: 40px; */
    height: 44px;
    line-height: 44px;
    font-weight: 350;
    font-family: PingFangSC-Regular,sans-serif,PingFangSC-Light,sans-serif,"Microsoft YaHei",arial;
  }

  .user-item-link{
    width: 100%;
    height: auto;
    padding: 8px 0;
    border-top: 1px solid #E8E8E8;
  }

  .user-item {
    padding-left: 25px;
  }

  .user-item span{
    margin-right: 14px;
    color: gray;
    position: relative;
    top: -1px;
    font-size: 17px !important;
  }

  .date{
    font-size: 12px;
    color: #666;
    margin-top: 1px;
  }


  .user-head img{
    width: 35px;
    height: 35px;
    border-radius: 200px;
    display: inline-block;
    vertical-align: middle;
  }

  .user-head span{
    display: inline-block;
    vertical-align: middle;
    margin-left: 4px;
    font-size: 12px;
    color: #555;
  }
  .right-nav-{
    position: absolute;
    right: 90px;
    color: #666;
    font-size: 16px;
    display: block;
    white-space: nowrap;
    vertical-align: top;
    height: 70px;
    line-height: 70px;
  }

  .right-nav- div{
    margin-left: 30px;
    display: inline-block;
    cursor: pointer;
  }

  .add img{
    width: 19px;
    height: 19px;
    margin-right: 8px;
    vertical-align: middle;
    position: relative;
    top: -2px;
  }

  .input-container{
    width: 100%;
    /*background: #42b983;*/
    height: 100%;
    position: relative;
    max-width: 480px;
    width: 100%;
  }

  .input-container span{
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0,-50%);
    cursor: pointer;
  }

  .input-container input{
    height: 40px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 14px;
    border: 0;
    outline: 0;
    color: #444;
    background: #f5f5f5;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    border-radius: 8px;
    padding-left: 17px;
    box-sizing: border-box;
  }

  .vip{
    position: relative;
    padding-left: 30px;
    font-style: oblique 30deg;
  }
  .vip::after{
    content:"";
    width: 18px;
    height: 18px;
    position: absolute;

    background-size: cover;
    background-repeat: no-repeat;
    left: 7px;
    top: 25px;
  }


  .vip1{
    color: #3271f3;
  }

  .vip1:after{
    background-image: url("../../assets/vip/l-1.svg");
  }

  .vip2{
    color: orange;
  }

  .vip2:after{
    background-image: url("../../assets/vip/l-2.svg");
  }
</style>

