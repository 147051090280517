// import Vue from 'vue'
// import VueRouter from 'vue-router'
//
// const Main= resolve => require(['../components/index/Main'],resolve);
//
//
// Vue.use(VueRouter)
//
// const routes = [
//   {
//     path: '/main',
//     component:Main
//   }
// ]
//
// const router = new VueRouter({
//   routes
// })

//挂载路由导航守卫 to将要访问得路径   from从哪里来 next 是否通过
// router.beforeEach((to, from, next) => {
//   if (to.path == '/login' ) {
//     return next()
//   }
//   //是否登录，未登录跳往登录页面
//   const token = localStorage.getItem('token')
//   console.log(token)
//   if (!token) {
//       console.log("前往后台登录")
//       return next('/login')
//     }
//   return next()
//
// })

import { createRouter, createWebHistory } from 'vue-router'

const routes = [

  {
    path: '/userMain',
    component: () => import('../components/production/UserMain'),
    children: [
      {
        path: '/addProduction',
        component: () => import('../components/production/AddProduction')
      },
      {
        path: '/productionIndex',
        component: () => import('../components/production/ProductionIndex')
      },
      {
        path: '/productionManger',
        component: () => import('../components/production/ProductionManger')
      },
      {
        path: '/userAuthentication',
        component: () => import('../components/user/UserAuthentication')
      },
      {
        path: '/shareUserList',
        component: () => import('../components/share/ShareUserList')
      },{
        path: '/productionDownHistory',
        component: () => import('../components/production/ProductionDownHistory')
      },
      {
        path: '/putHistory',
        component: () => import('../components/production/PutHistory')
      },{
        path: '/subAccountHistory',
        component: () => import('../components/production/SubAccountHistory')
      },{
        path: '/drawingsHistory',
        component: () => import('../components/production/DrawingsHistory')
      }
    ]
  },
  {
    path: '/userController',
    component: () => import('../components/user/UserController'),
    children: [
      {
        path: '/vipBuyList',
        component: () => import('../components/vip/VipBuyList')
      },
      {
        path: '/collectList',
        component: () => import('../components/user/CollectList')
      },
      {
        path: '/userDownHistory',
        component: () => import('../components/production/UserDownHistory')
      },
      {
        path: '/userMsg',
        component: () => import('../components/user/UserMsg')
      },
      {
        path: '/updatePassword',
        component: () => import('../components/user/UpdatePassword')
      },
    ]
  },
  {
    path: '/',
    name: 'index',
    component: () => import('../components/index/Main')
  },
  {
    path: '/craftList',
    component: () => import('../components/page/CraftList')
  }, {
    path: '/dieList',
    component: () => import('../components/die/DieList')
  },
  {
    path: '/dieDetail',
    component: () => import('../components/die/DieDetail')
  },
]
const routerHistory = createWebHistory()
const router = createRouter({
  history: routerHistory,
  routes
})
export default router



