import Vue from 'vue'
// import { Notification } from 'element-ui'

import axios from 'axios'


// axios.defaults.baseURL='http://192.168.1.4:8088' //这是配置基础url路径
// axios.defaults.baseURL='http://192.168.3.5:8088' //这是配置基础url路径
// axios.defaults.baseURL = 'http://localhost:8088' //这是配置基础url路径
axios.defaults.baseURL='http://7871092.vip:8088' //这是配置基础url路径

axios.interceptors.request.use(config => {
  config.headers.token = localStorage.getItem('token') //设置统一请求token
  config.headers['Content-Type'] = 'application/json'
  return config
})

import router from '../router'

axios.interceptors.response.use(res => {
  // console.log(res.data)
  let {
    state,
    data,
    message
  } = res.data

  if (state == 200) {
    return data
  } else if (state == 10) {
    // if (localStorage.getItem("type") == 2){
    //   router.push('/scanLogin');
    // }else{
    //   router.push('/login');
    // }

  } else {
    return  res.data
    // this.$message.warning("请填写激活码")
    //
    // Notification({
    //   title: '错误',
    //   message: message,
    //   type: 'error',
    //   duration: 2500
    // })
    // throw new Error(message)
    // return  false
    // this.$notify({type:'error', msg: data.message })
  }

})


export default axios

// const myMixin = {
//   created() {
//     this.$http = axios// 将axios到原型
//   }
// }
//
// Vue.mi
