<template>
  <div id="app">
    <Header></Header>
    <router-view></router-view>
  </div>

</template>

<script>
import Header from './components/index/Header'

export default {
  name: 'App',
  components: {
    Header
  }
}
</script>

<style>

#app{
  width: 100%;
  height: 100%;
  margin-top: 70px;
}

</style>
